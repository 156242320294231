<template>
  <div>
    <div class='main-loader' v-if='isLoading'></div>
    <div class="product-page">
      <b-container>
        <b-row>
          <swiper :options="swiperOptions" class="swiper-container mt-0 mb-0">
            <swiper-slide class="ss-width">
              <b-button 
                :variant="!selectedAgentType && !selectedProductType ? 'dark' : 'outline-secondary'"
                @click="handleFilter('all')"
                class="tab-button"
              >
                All
              </b-button>
            </swiper-slide>

            <swiper-slide 
              v-for="productType in uniqueProductTypes" 
              :key="`product-${productType}`"
              class="ss-width"
            >
              <b-button 
                :variant="selectedProductType === productType ? 'dark' : 'outline-secondary'"
                @click="handleFilter(productType)"
                class="tab-button"
              >
                {{ formatProductType(productType) }}
              </b-button>
            </swiper-slide>
          </swiper>
        </b-row>

        <div v-if="filteredImages.length">
          <b-row>
            <product-card 
              v-for="product in filteredImages" 
              :key="product.id" 
              :product="product" 
              :showLikeButton="false"
              :showShareButton="false"
              :showChatButton="false"
              :showDownloadButton="false"
              :showPurchaseButton="false"
              @toggle-like="updateLikeStatus" 
            />
          </b-row>
        </div>

        <div class="no-data mt-5 mb-5" v-else-if="!isLoading && !filteredImages.length">
          <div class="mx-auto text-center">
            <h2>{{ noDataMessage }}</h2>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>
  <script>
  import productCard from './imageCard.vue';
  import { mapState, mapActions } from 'vuex';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
  
  export default {
  name: 'TopCreations',

  metaInfo() {
    const pageTitle = 'Top Creations | Stunning Product Images Made With AI';
    const pageDescription = 'Discover the most stunning product images created with our AI platform. All these images were taken using a phone and then processed on our platform.';
    const pageUrl = 'https://briks.ai/explore';
    const pageImage = 'https://media.briks.ai/images/image-8bbac513-5e80-48f6-81b4-cd2a4ef29ba8.png'; 

    return {
      title: pageTitle,
      titleTemplate: '%s | BRIKS.AI',
      meta: [
        // Primary meta tags
        { name: 'description', content: pageDescription },
        { name: 'keywords', content: 'AI product photography, product images, AI generated images, fashion photography, product marketing' },
        
        // OpenGraph tags
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: pageUrl },
        { property: 'og:title', content: pageTitle },
        { property: 'og:description', content: pageDescription },
        { property: 'og:image', content: pageImage },
        
        // Twitter tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: pageUrl },
        { name: 'twitter:title', content: pageTitle },
        { name: 'twitter:description', content: pageDescription },
        { name: 'twitter:image', content: pageImage }
      ],
      link: [
        { rel: 'canonical', href: pageUrl }
      ],
      // Structured Data/Schema.org
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": pageTitle,
            "description": pageDescription,
            "url": pageUrl,
            "image": pageImage,
            "about": {
              "@type": "Thing",
              "name": "AI Product Photography",
              "description": "Professional product photography created using artificial intelligence"
            },
            "publisher": {
              "@type": "Organization",
              "name": "BRIKS.AI",
              "url": "https://briks.ai"
            }
          }
        }
      ]
    };
  },

  components: {
    productCard,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      isLoading: false,
      noDataMessage: 'Loading...',
      selectedAgentType: null,
      selectedProductType: null,
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
        grabCursor: true,
      },
      // Map for agent type display names
      agentTypeDisplayNames: {
        'product': 'Product Marketing',
        // 'influencer': 'Influencer Marketing'
      },
      productTypeDisplayNames: {
        'fashion': '👗 Fashion',
        'beauty': '💄 Beauty',
        'food': '🍔 Food',
        'health': '💪 Health & Wellness',
        'furniture': '🛋️ Furniture & Home Decor',
        'technology': '📱 Technology',
        'toys': '🧸 Toys',
        'automotive': '🚗 Automotive',
        'books': '📚 Books',
        'sports': '⚽ Sports',
        'pets': '🐾 Pets',
        'appliances': '🏠 Home Appliances',
        'seasonal': '🎄 Seasonal & Festival',
        'influencer':'📷 Influencer'
      }
    };
  },
  computed: {
    ...mapState('app', {
      featuredImages: state => state.featured_images,
    }),
    // Get unique agent types from images
    // uniqueAgentTypes() {
    //   return [...new Set(this.featuredImages.map(image => image.agentType))].filter(Boolean);
    // },
    uniqueProductTypes() {
  // Get all product types from images
  const allTypes = this.featuredImages.map(image => image.productType).filter(Boolean);
  
  // Create a map to group types by their normalized value
  const normalizedMap = {};
  
  // Group types by their normalized form
  allTypes.forEach(type => {
    const normalized = this.normalizeProductType(type);
    // Prefer the display format if available
    if (!normalizedMap[normalized] || 
        (this.productTypeDisplayNames[normalized] && 
         type === this.productTypeDisplayNames[normalized])) {
      normalizedMap[normalized] = type;
    }
  });
  
  // Return unique types based on normalized values
  return Object.values(normalizedMap);
},
filteredImages() {
  return this.featuredImages.filter(image => {
    const matchesAgentType = !this.selectedAgentType || image.agentType === this.selectedAgentType;
    
    // Use normalized comparison for product types
    const matchesProductType = !this.selectedProductType || 
      this.normalizeProductType(image.productType) === this.normalizeProductType(this.selectedProductType);
    
    return matchesAgentType && matchesProductType;
  });
}
  },
  watch: {
    // Watch for route changes
    '$route.hash': {
      immediate: true,
      handler(newHash) {
        this.handleRouteChange(newHash);
      }
    }
  },
  mounted() {
    this.loadFeaturedImagesFromStorage();
    this.fetchFeaturedItems();
    this.handleRouteChange(window.location.hash);
  },
  methods: {
    ...mapActions('app', ['fetchFeaturedItems', 'loadFeaturedImagesFromStorage']),
    handleRouteChange(hash) {
    // Remove the # from the hash
    const filter = hash.replace('#', '');
    
    if (!filter) {
      // No hash in URL, show all items
      this.selectedProductType = null;
      return;
    }

    // Normalize the filter from URL
    const normalizedFilter = this.normalizeProductType(filter);
    
    // Find a matching product type using normalized comparison
    const validFilter = this.uniqueProductTypes.find(type => 
      this.normalizeProductType(type) === normalizedFilter
    );

    if (validFilter) {
      this.selectedProductType = validFilter;
    } else {
      // Invalid filter, reset to show all
      this.selectedProductType = null;
    }
  },

  handleFilter(productType) {
    if (productType === 'all') {
      // Remove hash from URL when showing all items
      window.location.hash = '';
      this.selectedProductType = null;
    } else {
      // Use normalized key for URL hash (without emoji)
      const hashValue = this.normalizeProductType(productType);
      window.location.hash = hashValue;
      this.selectedProductType = productType;
    }
  },
  
  clearAllFilters() {
    this.selectedAgentType = null;
    this.selectedProductType = null;
  },
    filterByProductType(productType) {
      this.selectedProductType = productType;
      this.selectedAgentType = null; // Clear agent type when product type is selected
    },

       // Filter images by agent type
       filterByAgentType(agentType) {
      this.selectedAgentType = agentType;
    },
    // Format agent type for display
    formatAgentType(type) {
      if (!type) return 'Unknown';
      return this.agentTypeDisplayNames[type.toLowerCase()] || type.charAt(0).toUpperCase() + type.slice(1);
    },

    removeEmojis(str) {
    if (!str) return '';
    return str.replace(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu, '').trim();
  },

  normalizeProductType(type) {
    if (!type) return '';
    
    // Check if it exactly matches a display name
    for (const [key, value] of Object.entries(this.productTypeDisplayNames)) {
      if (type === value) {
        return key;
      }
    }
    
    // Check if it matches a key directly
    const lowerType = type.toLowerCase();
    if (this.productTypeDisplayNames[lowerType]) {
      return lowerType;
    }
    
    // Check if it matches "emoji + key" pattern
    for (const key of Object.keys(this.productTypeDisplayNames)) {
      // Case insensitive match for "<anything> key" pattern
      const pattern = new RegExp(`^.*\\s+${key}$`, 'i');
      if (pattern.test(type)) {
        return key;
      }
    }
    
    // Final fallback: remove emojis and normalize
    return this.removeEmojis(type).toLowerCase();
  },

  formatProductType(type) {
    if (!type) return 'Unknown';
    
    // Get normalized form of the type
    const normalizedType = this.normalizeProductType(type);
    
    // Look up display name using normalized type
    return this.productTypeDisplayNames[normalizedType] || 
           (type.charAt(0).toUpperCase() + type.slice(1));
  },

    async updateLikeStatus(imageId, likeStatus) {
      const user = JSON.parse(localStorage.getItem('userData'));
      const userId = user?.user_id;

      if (!userId) {
        console.error('User ID is not available in local storage');
        return;
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/api/updateLikeStatus`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId, imageId, likeStatus })
        });

        const data = await response.json();

        if (data.message === 'Like status updated successfully!') {
          const image = this.featuredImages.find(img => img.id === imageId);
          if (image) {
            image.like_or_dislike = likeStatus;
            if (likeStatus === 'N') {
              this.featuredImages = this.featuredImages.filter(img => img.id !== imageId);
            }
          }
        }
      } catch (error) {
        console.error('Error updating like status:', error);
      }
    },
    toggleTopic(topic) {
    // Toggle topic selection
    if (this.isSelected(topic)) {
      this.selectedTopics = this.selectedTopics.filter(selectedTopic => selectedTopic !== topic);
    } else {
      this.selectedTopics.push(topic);
    }

    // Store updated selected topics in localStorage
    localStorage.setItem('selectedTopics', JSON.stringify(this.selectedTopics));

    // Rearrange tabs dynamically after selection change
    this.generateTabs();

    // Reset pagination to the first page
    this.currentPage = 1;
  },

  generateTabs() {
    const topics = [...new Set(this.feedImages.map(image => image.topic))];

    // Separate selected and non-selected topics
    const selectedTopics = topics.filter(topic => this.selectedTopics.includes(topic));
    const nonSelectedTopics = topics.filter(topic => !this.selectedTopics.includes(topic));

    // Merge selected topics first, followed by non-selected topics
    const orderedTopics = [...selectedTopics, ...nonSelectedTopics];

    // Update the tabs array, marking selected topics as active
    this.tabs = orderedTopics.map(topic => ({
      topic,
      active: this.isSelected(topic),
    }));
  },

  // Helper function to check if a topic is selected
  isSelected(topic) {
    return this.selectedTopics.includes(topic);
  },
  }
};
</script>
  
  <style scoped>
  .search-input {
    border-radius: 0rem !important;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
 .swiper-slide {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: auto !important;
  box-sizing: border-box;
}
  
  .swiper-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-left: 16px !important;
  margin-right: 6px !important;
  margin-top: 0px !important;
  padding-bottom: 5px !important;
  padding-top: 0px !important;
}
  
  .swiper-container::-webkit-scrollbar {
    display: none;
  }


/* Swiper and tab styles */
.swiper-slide {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: auto !important;
  box-sizing: border-box;
}


.swiper-container::-webkit-scrollbar {
  display: none;
}

.tab-button {
  font-size: 1rem !important;
  margin-bottom: 8px;
  margin-top: 2px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0.48571rem 0.71429rem !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: hsl(0, 0%, 100%);
  transition: all 0.2s ease-in-out;
}

.tab-button:hover,
.tab-button.is-active {
  background-color: #0069d9;
}

.b-button {
  border-radius: 0.25rem;
}

.ss-width {
  width: auto !important;
}

.infinite-scroll-trigger {
  width: 100%;
  height: 50px;
}
</style>
  